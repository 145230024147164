import React, { Component } from 'react';
import { Swiper } from 'swiper/js/swiper.esm';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import './../css/component/client-brand.css';
import 'swiper/css/swiper.css';

const clientBrandList = [
    {
        imagUrl: "01",
        imageAlt: 'cimage1'
    },
    {
        imagUrl: "02",
        imageAlt: 'cimage2'
    },
    {
        imagUrl: "03",
        imageAlt: 'cimage3'
    },
    {
        imagUrl: "01",
        imageAlt: 'cimage4'
    },
    {
        imagUrl: "03",
        imageAlt: 'cimage5'
    },
    {
        imagUrl: "02",
        imageAlt: 'cimage6'
    }
]

const params = {
    // Provide Swiper class as props
    Swiper,
    containerClass: 'client-logo-container',
    spaceBetween: 50,
    slidesPerView: 5,
    loop: true,
    breakpoints: {
        990: {
            slidesPerView: 4
        },
        768: {
            slidesPerView: 3
        },
        540: {
            slidesPerView: 2
        },
        400: {
            slidesPerView: 1
        }
    }
  }

class ClientBrand extends Component {
    render() {
        return (
            <ReactIdSwiperCustom {...params}>
                {clientBrandList.map((val , i) => (
                    <div className="swiper-slide" key={i}>
                        <div className="client-logo">
                            <img
                                src={`assets/images/client/${val.imagUrl}.png`} alt={`${val.imageAlt}`}
                            />
                        </div>
                    </div>
                ))}
            </ReactIdSwiperCustom>
        )
    }
}

export default ClientBrand;