import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, Pagination } from 'swiper/js/swiper.esm';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import { FaSearch, FaLink } from "react-icons/fa";
import './../css/component/team.css';
import 'swiper/css/swiper.css';

const teamList = [
    {
        imagUrl: "1",
        imageAlt: 'author1',
        text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore etiam processus ',
        name: 'John Bordir',
        designation: 'Designer'
    },
    {
        imagUrl: "2",
        imageAlt: 'author2',
        text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore etiam processus ',
        name: 'Tomas More',
        designation: 'Developer'
    },
    {
        imagUrl: "3",
        imageAlt: 'author3',
        text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore etiam processus ',
        name: 'Kadre John',
        designation: 'Photographer'
    },
    {
        imagUrl: "1",
        imageAlt: 'Nil Johny',
        text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore etiam processus ',
        name: 'John Bordir',
        designation: 'Designer'
    },
    {
        imagUrl: "3",
        imageAlt: 'Charl Nelson',
        text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore etiam processus ',
        name: 'John Bordir',
        designation: 'Designer'
    },
    {
        imagUrl: "2",
        imageAlt: 'author6',
        text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore etiam processus ',
        name: 'David Clark',
        designation: 'Developer'
    }
]

const params = {
    // Provide Swiper class as props
    Swiper,
    containerClass: 'team-slider-container',
    spaceBetween: 30,
    slidesPerView: 1,
    loop: true,
    // Add modules you need
    modules: [Pagination],
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
        990: {
            slidesPerView: 4
        },
        768: {
            slidesPerView: 3
        },
        540: {
            slidesPerView: 2
        },
        400: {
            slidesPerView: 1
        }
    }
  }

class Team extends Component {
    render() {
        return (
            <ReactIdSwiperCustom {...params}>
                {teamList.map((val , i) => (
                    <div className="swiper-slide" key={i}>
                        <div className="team-item">
                            <div className="team-thumb">
                                <img
                                    src={`assets/images/team/${val.imagUrl}.jpg`} alt={`${val.imageAlt}`}
                                 />
                                <Link className="icon search" to="/"><FaSearch/></Link>
                                <Link className="icon link" to="/"><FaLink/></Link>
                            </div>
                            <div className="team-content">
                                <h6 className="name">{val.name}</h6>
                                <p className="desgination">{val.designation}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </ReactIdSwiperCustom>
        )
    }
}

export default Team;