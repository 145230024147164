import React, { Component } from 'react';
import { FaCloudUploadAlt, FaRegCommentAlt, FaRegImages, FaUsersCog } from "react-icons/fa";
import SectionHeader from './../component/section-header';
import './../css/feature.css';

const FeatureList = [
    {
        icon: <FaCloudUploadAlt />,
        title: 'Automation and Scale',
        description: 'Automation enables organizations to scale more effectively. By automating key processes, businesses can handle increased demand without proportionally increasing their labor costs'
    },
    {
        icon: <FaRegImages />,
        title: 'Exclusive Design',
        description: 'In the context of exclusive design, clients often seek a product or service that stands out and reflects their uniqueness of their brand'
    },
    {
        icon: <FaUsersCog />,
        title: 'Dedicated Engineers',
        description: 'Specialists in the field of engineering who are committed to their work and specific projects or tasks. These engineers are highly focused and devoted to their responsibilities and the goals'
    },
]

// header section content
const title = <h3 className="mb--25">A Robust architecture is <br/>important to know your business limits</h3>;
const subtitle = <h6></h6>;
const alignment = 'section-header text-center pb--60';

class Feature extends Component {
    render() {
        return (
            <section id="feature" className={`${this.props.featureStyle ? this.props.featureStyle : ""}`}>
                <div className="container">
                    <SectionHeader 
                        title={ title } 
                        subtitle={ subtitle }
                        alignment={alignment}
                    />
                </div>
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-start">
                        {FeatureList.map( (val , i) => (
                            <div className="col-md-6 col-lg-4 mb--30 mb-lg-0" key={i}>
                                <div className="feature-item text-left" style={{height: 450}}>
                                    <div className="icon-box">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <img 
                                            src="assets/images/feature/feature-dot-shape.png" 
                                            alt="dot shape"
                                        />
                                    </div>
                                    <h5>{val.title}</h5>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        )
    }
}

export default Feature;