import React, { Component } from 'react';
import PlayStore from './../component/playstore';
import './../css/banner2.css';
const bannerTitle = 'Best application for your business & solution.';
const bannerDesc = 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris amet nibh. Donec sodales sagittis magna.!!';


class Banner2 extends Component {
    render() {
        return (
            // <section id='banner' className="banner-section bg-ash-color2 style2">
            <section id="banner" className={`${this.props.bannerStyle ? this.props.bannerStyle : ""}`}>
                <ul className="shape-animation list-unstyled m-0 p-0">
                    <li className="cross-shape one"><span></span></li>
                    <li className="cross-shape two"><span></span></li>
                    <li className="rect-shape one"><span></span></li>
                    <li className="rect-shape two"><span></span></li>
                    <li className="rect-shape three"><span></span></li>
                    <li className="rect-shape four"><span></span></li>
                </ul>
                <div className="banner-content d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="banner-text">
                                    <h1>{ bannerTitle }</h1>
                                    <p>{ bannerDesc }</p>
                                    <PlayStore />
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
                <div className="banner-image-content d-flex align-items-center justify-content-center justify-content-lg-start">
                    <div className="banner-image pr--30 pl--30 pl-lg-0">
                        <div className="banner-joint-image">
                            <img className="img1" src="assets/images/banner/img1.png" alt="shape1" />
                            <img className="img2" src="assets/images/banner/img2.png" alt="shape2" />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Banner2;
