
import React, { Component } from 'react';
import Testimonial2 from './../component/testimonial2';
import SectionHeader from './../component/section-header';

// header section content
const title = <h3 className="mb-20">What’s our clients are saying</h3>;
const alignment = 'section-header text-center pb--60';

class TeamSection extends Component {
    render() {
        return (
            <section id="testimonial" className={`${this.props.testimonialStyle ? this.props.testimonialStyle : ""}`}>
                <div className="container">
                    <SectionHeader 
                        title={ title }
                        alignment={alignment}
                    />
                </div>
                <div className="container">
                    <Testimonial2 /> 
                </div>
            </section>
        )
    }
}

export default TeamSection;