import React, { Component } from 'react';
import ClientBrand from './../component/client-brand';

class ClientBrandSection extends Component {
    render() {
        return (
            <section className={`${this.props.clientSectionStyle ? this.props.clientSectionStyle : ""}`}>
                <div className="container">
                    <ClientBrand /> 
                </div>
            </section>
        )
    }
}

export default ClientBrandSection;