import React, { Component } from 'react';
import PlayStore from './../component/playstore';
import './../css/banner.css';
const bannerTitle = 'System Design Experts';
const bannerDesc = 'Elevating Technology, Elevating You';


class Banner extends Component {
    render() {
        return (
            <section id="banner" className={`${this.props.bannerStyle ? this.props.bannerStyle : ""}`}>
                <div className="banner-content d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="banner-text">
                                    <h1>{ bannerTitle }</h1>
                                    <p>{ bannerDesc }</p>
                                    <PlayStore />
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
                <div className="banner-image-content d-flex align-items-center justify-content-center justify-content-lg-start">
                    <div className="banner-image pr--30 pl--30 pl-lg-0">
                        <div className="banner-joint-image">
                            <img className="img1" src="assets/images/banner/img1.png" alt="shape1" />
                            <img className="img2" src="assets/images/banner/img2.png" alt="shape2" />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Banner;