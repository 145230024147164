import React, { Component } from 'react';
import PlayStore from './../component/playstore';
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import './../css/footer.css';

class Footer extends Component {
    render() {
        return (
            <footer className={`${this.props.footerStyle ? this.props.footerStyle : ""}`}>
                <div className="footer-top pt--100 pt_sm--120 pb--40 pb_lg--90">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="footer-widget text-center text-lg-left mb--30 mb-lg-0">
                                    <Link to="/" className="footer-logo">
                                        <img 
                                            src="assets/images/logo.png" 
                                            alt="logo"
                                        />
                                    </Link>
                                    <ul className="footer-contact-info pl-0 list-unstyled">
                                        <li><a href="mailto:admin@connectjs.in?Subject=Hello Team" target="_top"><strong>Email:</strong> admin@connectjs.in</a></li>
                                        <li><strong>Address:</strong> 302, Sri Dakshyani Yemlur, Bangalore, KA - 560036</li>
                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <p>&copy;  Copyrights 2024 <Link to="/">ConnectJs</Link> All rights reserved.</p>
                            <p>Maintained <span className="ti-heart"></span>by <Link to="/"> ConnectJs</Link></p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;
