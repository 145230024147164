import React, { Component } from 'react';
import './../css/component/playstore.css';
import { FaBoxes, FaAws, FaMobile, FaUsersCog } from "react-icons/fa";
import { BiNetworkChart, BiCartAlt } from "react-icons/bi" ;
import { SiCraftcms } from "react-icons/si";


class PlayStore extends Component { 
    render() {
        return (
            <div className="play-store">
                <a href="/" className="custom-btn d-inline-flex align-items-center"><BiNetworkChart /><p>IoT</p></a>
                <a href="/" className="custom-btn d-inline-flex align-items-center"><FaBoxes /><p>ERP</p></a>
                <a href="/" className="custom-btn d-inline-flex align-items-center"><BiCartAlt /><p>E-COMM</p></a>
                <a href="/" className="custom-btn d-inline-flex align-items-center"><SiCraftcms /><p>CMS</p></a>
                <a href="/" className="custom-btn d-inline-flex align-items-center"><FaAws /><p>CLOUD Native</p></a>
                <a href="/" className="custom-btn d-inline-flex align-items-center"><FaMobile /><p>MOBILE</p></a>
                <a href="/" className="custom-btn d-inline-flex align-items-center"><FaUsersCog /><p>TA-Support</p></a>
            </div>
        )
    }
}

export default PlayStore;