import React, { Component } from 'react';
import { FaCloudUploadAlt, FaRegCommentAlt, FaRegImages } from "react-icons/fa";
import SectionHeader from '../component/section-header';
import './../css/feature.css';

const FeatureList = [
    {
        icon: <FaCloudUploadAlt />,
        title: 'Your Data in cloud',
        description: 'Lorem ipsum dolor sit amet, conseda adipiscing elit. Aenean commodo ligula eget dolorAenean massa. Cum sociind'
    },
    {
        icon: <FaRegCommentAlt />,
        title: '24/7 Support',
        description: 'Lorem ipsum dolor sit amet, conseda adipiscing elit. Aenean commodo ligula eget dolorAenean massa. Cum sociind'
    },
    {
        icon: <FaRegImages />,
        title: 'Exclusive Design',
        description: 'Lorem ipsum dolor sit amet, conseda adipiscing elit. Aenean commodo ligula eget dolorAenean massa. Cum sociind'
    },
    {
        icon: <FaCloudUploadAlt />,
        title: 'Your Data in cloud',
        description: 'Lorem ipsum dolor sit amet, conseda adipiscing elit. Aenean commodo ligula eget dolorAenean massa. Cum sociind'
    },
    {
        icon: <FaRegCommentAlt />,
        title: '24/7 Support',
        description: 'Lorem ipsum dolor sit amet, conseda adipiscing elit. Aenean commodo ligula eget dolorAenean massa. Cum sociind'
    },
    {
        icon: <FaRegImages />,
        title: 'Exclusive Design',
        description: 'Lorem ipsum dolor sit amet, conseda adipiscing elit. Aenean commodo ligula eget dolorAenean massa. Cum sociind'
    }
]

// header section content
const title = <h3 className="mb--25">A valuable feature is more <br/>important to know our apps deep.</h3>;
const subtitle = <h6>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo<br />justo. Nullam dictum felis eu pede mollis pretium.</h6>;
const alignment = 'section-header text-center pb--60';

class Feature3 extends Component {
    render() {
        return (
            <section id="feature" className={`${this.props.featureStyle ? this.props.featureStyle : ""}`}>
                <div className="container">
                    <SectionHeader 
                        title={ title } 
                        subtitle={ subtitle }
                        alignment={alignment}
                    />
                </div>
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-start">
                        {FeatureList.map( (val , i) => (
                            <div className="col-md-6 col-lg-4 mb--30 mb-lg-0" key={i}>
                                <div className="feature-item style3">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <h5>{val.title}</h5>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        )
    }
}

export default Feature3;