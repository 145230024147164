import React, { Component } from 'react';
import { FaPlay} from "react-icons/fa";
import './../css/banner3.css';
const bannerTitle = 'Best application for your business & solution.';
const bannerDesc = 'Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris amet nibh. Donec sodales sagittis magna.!!';


class Banner3 extends Component {
    render() {
        return (
            <section id="banner" className={`${this.props.bannerStyle ? this.props.bannerStyle : ""}`}>
                <div className="banner-content d-flex align-items-center">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div class="banner-text">
                                    <h1>Best application for your business &amp; solution.</h1>
                                    <p>Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. </p>
                                    <div class="button-store3 wow fadeInUp">
                                        <a href="#" class="custom-btn d-inline-flex align-items-center m-2 m-sm-0 mr-sm-3">Get Started</a>
                                        <a data-rel="lightcase:myCollection" href="https://www.youtube.com/embed/6lt2JfJdGSY" class="play-btn m-2 m-sm-0 d-inline-flex align-items-center">
                                            <span class="play"><FaPlay/></span>
                                            <span class="text">Play Video</span>
                                        </a>
                                    </div>
                                </div>
                            </div>  
                            <div className="col-lg-6 text-center text-lg-right">
                                <div class="banner-form text-left">
                                    <h5>Get Started for Free.</h5>
                                    <p>30-day free trial. Instant access. No credit card required. </p>
                                    <form>
                                        <div class="form-group">
                                            <label for="inputName1">Your Name</label>
                                            <input type="name" class="form-control" id="inputName1" placeholder="user@yourste.com"/>
                                        </div>

                                        <div class="form-group">
                                            <label for="inputEmail3">Email</label>
                                            <input type="email" class="form-control" id="inputEmail3" placeholder="Ender yor email address"/>
                                        </div>

                                        <div class="form-group">
                                            <label for="inputPassword4">Password</label>
                                            <input type="password" class="form-control" id="inputPassword4" placeholder="******"/>
                                        </div>
                                        <div class="form-group">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="gridCheck"/>
                                                <label class="form-check-label" for="gridCheck">
                                                I Agree to the Terms &amp; Conditions
                                                </label>
                                            </div>
                                        </div>
                                        <button type="submit" class="submit">Sign Up</button>
                                    </form>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Banner3;
